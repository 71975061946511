<template>
  <div class="icon">?</div>
  <h1>404</h1>
  {{ $t('global.errors.not_found') }}
</template>

<style scoped lang="scss">
.icon {
  font-size: 150px;
  font-weight: bold;
}
</style>