import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'

import './assets/global.scss';
import router from './router'

import en from './assets/translations/en'
import fr from './assets/translations/fr'

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        fr
    },
})

createApp(App).use(router).use(i18n).mount('#app')
