<template>
  <main role="main">
    <h1>{{ $t("character_select.title") }}</h1>
      <div class="character" tabindex="0"
           v-for="(character, id) in characters[$route.params.lang]"
           :key="id">
        <div class="character-inner">
          <div class="picture">
            <img :src="character.picture_recto" :alt="'Picture of ' + character.name"/>
          </div>
          <router-link class="more-info" :to="'/' + $route.params.lang + '/story/' + id" :style="{ 'background-image': 'url(' + character.picture_verso + ')' }">
            <div class="name">{{ character.name }}</div>
            <div class="desc">{{ character.desc }}</div>
            <div class="continue">{{ $t("character_select.continue") }}</div>
          </router-link>
        </div>
      </div>
  </main>
</template>

<script>
import characters from '../assets/characters.json';

export default {
  name: "CharacterSelect",
  data() {
    return {
      characters
    }
  },
  beforeCreate() {
    this.$i18n.locale = this.$route.params.lang.toLowerCase();
  },
}
</script>

<style scoped lang="scss">
h1 {
  margin-bottom: 30px;
}
.character {
  display: inline-block;
  cursor: pointer;
  margin: 5px 15px;
  background-color: transparent;
  width: 247px;
  height: 340px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */

  /* This container is needed to position the front and back side */
  .character-inner {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 17px;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: 30px;

    .more-info {
      background-size: cover;
    }

    /* Position the front and back side */
    .picture, .more-info {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      color: #222;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
    }

    .picture img {
      height: 100%;
    }

    /* Style the back side */
    .more-info {
      display: block;
      padding: 20px;
      text-decoration: none;
      transform: rotateY(180deg);

      .name {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 150%;
      }

      .continue {
        margin-top: 25px;
        font-size: 80%;
        opacity: .8;
      }
    }
  }

  /* Do an horizontal flip when you move the mouse over the flip box container */
  &:hover .character-inner, &:focus .character-inner {
    transform: rotateY(180deg);
  }
}
</style>