<template>
  <header role="banner">
    <nav role="navigation">
      <router-link :to="'/' + $i18n.locale"><div class="logo">Ways2Mobility</div></router-link>
      <select v-model="$i18n.locale" @change="onLanguageChange($event)" class="language-switcher">
        <option v-for="(option, i) in options" :key="`options${i}`" :value="option.value" :selected="option.value === $i18n.locale">
          {{ option.text }}
        </option>
      </select>
    </nav>
  </header>
  <router-view/>
  <footer role="contentinfo">
    <div>
      {{ $t('footer.sponsor') }}
      <br>© 2022 Ways2Mobility (<router-link :to="'/' + $i18n.locale + '/about'">{{ $t('nav.about') }}</router-link>)
    </div>
  </footer>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      options: [
        { text: 'english', value: 'en' },
        { text: 'français', value: 'fr' }
      ],
    }
  },
  methods: {
    onLanguageChange(event) {
      window.location.href = '/#/'+event.target.value;
      window.location.reload();
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  header {
    text-align: left;
    margin: 30px 80px 20px;
    @media (max-width: 599px) {
      margin: 30px;
    }

    nav {
      vertical-align: center;

      .logo {
        display: inline-block;
        font-size: 30px;
      }

      a {
        margin-left: 20px;
        color: darkblue;
        text-decoration: none;
        opacity: 1;
        transition: .3s;
        @media (max-width: 599px) {
          display: block;
        }

        &:hover {
          opacity: .6;
          text-decoration: underline;
        }
      }

      .language-switcher {
        float: right;
        margin-right: 30px;
        border: none;
        background: #f3f4f7;
        font-size: 140%;
      }
    }
  }

  footer {
    margin-top: 40px;
    opacity: .5;
    transition: opacity .2s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
