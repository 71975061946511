<template>
  <div id="quiz-table">
    <h2>Little Quiz</h2>
    <div v-for="qa in questions" :key="qa.id" class="quiz-q" v-bind:class="qa.a">
      <div class="q-a">
        <div class="question">{{ qa.q }}</div>
        <div class="answers" v-bind:class="{ 'stdTrue': answers[qa.id] === true, 'stdFalse': answers[qa.id] === false }">
          <button class="true-button" @click="answer(qa.id, true)">✓<br>{{ $t('games.true_false_game.true') }}</button>
          <button class="false-button" @click="answer(qa.id, false)">×<br>{{ $t('games.true_false_game.false') }}</button>
        </div>
      </div>
      <div class="result">
        <b v-show="finished && (qa.a === answers[qa.id]+'')">{{ $t('games.true_false_game.correct_phrase') }}</b>
        {{ $t('games.true_false_game.result') }} <strong>{{ qa.a }}</strong>. <span v-html="qa.more"></span>
      </div>
    </div>
    <button id="check-answers-button" @click="checkAnswers()" v-show="!finished">
      {{ $t('games.true_false_game.submit_answers')}}
    </button>
    <div id="scores" v-show="finished">
      {{ $t('games.true_false_game.correct_count', {'count': correctAnswersCount, 'total': questions.length}) }}
      <br>{{ $t('games.true_false_game.score')}} <strong>{{ Math.floor((correctAnswersCount/questions.length)*100) }}%</strong>.
    </div>
  </div>
</template>

<script>
import gameContent from '../assets/games/truefalse_content.json';
export default {
  name: "TrueFalseGame",
  data() {
    return {
      answers: [],
      finished: false,
      correctAnswersCount: 0,
      gameContent: gameContent,
      questions: []
    }
  },
  mounted() {
    this.questions = gameContent[this.$route.params.lang][this.$route.params.character]
  },
  methods: {
    checkAnswers: function() {
      this.finished = true;
      this.$emit('trueFalseGameFinished', true)
      document.querySelector('#quiz-table').classList.add('finished');

      let correctAnswersCount = 0;
      this.questions.forEach(qa => {
        if ((qa.a === 'true') === this.answers[qa.id]) {
          correctAnswersCount++;
        }
      });
      this.correctAnswersCount = correctAnswersCount;

    },
    answer: function(id, value) {
      if (!this.finished) {
        this.answers[id] = value;
      }
    }
  }
}
</script>

<style scoped lang="scss">
$trueColor: #269d26;
$falseColor: #ce3030;

#quiz-table {
  margin: 50px 0;

  button {
    cursor: pointer;
  }

  .quiz-q {
    width: 90%;
    margin: 20px auto;
    padding: 20px 10px;
    background: #ddd;
    border-left: 8px solid grey;

    .q-a {
      display: flex;
      flex-direction: row;

      .question {
        flex: 1;
      }

      .answers {
        width: 150px;
        align-self: center;

        button {
          padding: 5px 15px;
          margin-left: 4px;
          border-radius: 5px;
          font-size: 80%;
          border: 3px solid transparent;
        }

        &.stdTrue .true-button, &.stdFalse .false-button {
          border: 3px solid #383892;
        }
      }
    }

    .result {
      margin: 30px 30px 10px;
      font-size: 90%;
      display: none;
    }
  }

  #check-answers-button {
    font-size: 110%;
    padding: 10px 20px;
  }

  &.finished {
    .answers button {
      cursor: not-allowed;
    }

    .quiz-q.true {
      .true-button { background-color: lighten($trueColor, 30%); }
      .stdFalse .false-button { text-decoration: line-through; }
      strong { color: $trueColor; }
    }

    .quiz-q.false {
      .false-button { background-color: lighten($falseColor, 30%); }
      .stdTrue .true-button { text-decoration: line-through; }
      strong { color: $falseColor; }
    }

    .result {
      display: block;
    }
  }
}

</style>
