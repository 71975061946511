<template>
  <main role="main" v-if="loading">
    {{ $t('global.loading') }}
  </main>
  <p v-if="error">
    {{ $t('global.errors.main') }}
  </p>
  <transition name="fade" v-if="!loading && path">
    <main role="main" class="message" v-show="showMessage">
      <p class="text" v-html="currentMessage.text"></p>
      <div v-if="countrySelectorDisplay">
        <CountrySelector @hideShowChoices="hideShowChoices"></CountrySelector>
      </div>
      <div v-if="crosswordGameDisplay">
        <CrosswordGame @crosswordGameFinished="modifyChoice($t('global.actions.continue'))"></CrosswordGame>
      </div>
      <div v-if="trueFalseGameDisplay">
        <TrueFalseGame @trueFalseGameFinished="modifyChoice($t('global.actions.continue'))"></TrueFalseGame>
      </div>
      <div v-if="guessWordsGameDisplay">
        <GuessWordsGame @guessWordsGameFinished="modifyChoice($t('global.actions.continue'))"></GuessWordsGame>
      </div>
      <div class="options" v-show="showChoices">
        <div class="choice" v-for="choice in currentMessage.choices" :key="choice" @click="goTo(choice.next)">
          {{ choice.option }}
        </div>
      </div>
      <p class="additional-text" v-if="currentMessage.additional_text !== false" v-html="currentMessage.additional_text"></p>
      <div class="more-actions">
        <a @click="previous()" v-if="history.length > 0">{{ $t('global.actions.previous') }}</a>
        <a @click="reset()" v-if="currentMessage.id !== 1 && currentMessage.id !== '1'">{{ $t('global.actions.reset') }}</a>
      </div>
    </main>
  </transition>
  <div class="debug-info">#{{ currentMessage.id }}</div>
</template>

<script>
import CountrySelector from "@/components/CountrySelector";
import CrosswordGame from "@/components/CrosswordGame";
import TrueFalseGame from "@/components/TrueFalseGame";
import GuessWordsGame from "@/components/GuessWordsGame";
export default {
  name: 'MessagePath',
  components: {CrosswordGame, CountrySelector, TrueFalseGame, GuessWordsGame},
  data() {
    return {
      path: null,
      loading: false,
      error: false,
      showMessage: true,
      showChoices: true,
      currentMessage: {},
      history: [],
      countrySelectorDisplay: false,
      crosswordGameDisplay: false,
      trueFalseGameDisplay: false,
      guessWordsGameDisplay: false
    }
  },
  beforeCreate() {
    this.$i18n.locale = this.$route.params.lang.toLowerCase();
  },
  mounted() {
    this.loading = true;
    localStorage.removeItem('countryName');

    fetch('/paths/' + this.$route.params.lang + '_' + this.$route.params.character+'.json', {
      method: 'get',
      headers: {
        'content-type': 'application/json'
      }
    })
        .then(res => {
          // a non-200 response code
          if (!res.ok) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then(json => {
          // set the response data
          this.path = json;
          this.display(1);
        })
        .catch(err => {
          console.log('error', err);
          this.error = true;
        })
        .then(() => {
          this.loading = false;
        });
  },
  methods: {
    display(messageId) {

      let messageToDisplay = {
        "id": messageId,
        "text": this.path[messageId].text,
        "choices": JSON.parse(JSON.stringify(this.path[messageId].choices)),
        "additional_text": false
      }

      if ('additional_text' in this.path[messageId]) {
        messageToDisplay.additional_text = this.path[messageId].additional_text
      }

      // replace variables with data in local storage
      if (localStorage.countryName !== undefined) {
        messageToDisplay.text = messageToDisplay.text.replaceAll('[name of the country]', localStorage.countryName);
        if (messageToDisplay.choices !== undefined) {
          messageToDisplay.choices.forEach(choice => {
            choice.option = choice.option.replaceAll('[name of the country]', localStorage.countryName);
          });
        }
      }

      // show/hide needed components for message
      this.showChoices = true;
      this.countrySelectorDisplay = false;
      this.crosswordGameDisplay = false;
      this.trueFalseGameDisplay = false;
      this.guessWordsGameDisplay = false;
      if ('extra' in this.path[messageId]) {
        if (this.path[messageId].extra.includes("countrySelector")) {
          this.countrySelectorDisplay = true;
          this.showChoices = false;
        }
        if (this.path[messageId].extra.includes("crosswordGame")) {
          this.crosswordGameDisplay = true;
        }
        if (this.path[messageId].extra.includes("trueFalseGame")) {
          this.trueFalseGameDisplay = true;
        }
        if (this.path[messageId].extra.includes("guessWordsGame")) {
          this.guessWordsGameDisplay = true;
        }
      }

      this.currentMessage = messageToDisplay;
    },
    goTo(messageId) {
      if (messageId < 0) {
        this.$router.push({ path: '../' })
      } else {
        this.history.push(this.currentMessage.id);
        this.display(messageId);
      }
    },
    previous() {
      this.display(this.history.pop());
    },
    reset() {
      this.showMessage = false;
      let that = this;
      setTimeout(function () {
        that.history = [];
        that.display(1);
        that.showMessage = true;
        localStorage.removeItem('countryName');
      }, 500);
    },
    hideShowChoices(bool) {
      this.showChoices = bool;
    },
    modifyChoice(newText) {
      document.querySelector('.options .choice').innerHTML = newText;
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.debug-info {
  color: transparent;
}

.message {
  background-color: white;
  border-radius: 10px;
  //box-shadow: black 0 0 1px;
  color: #222;
  padding: 30px;
  max-width: 800px;
  margin: auto;
  font-size: 1.2em;
  @media (max-width: 599px) {
    padding: 10px;
  }

  .text {
    white-space: pre-line
  }

  .options {
    .choice {
      width: 100%;
      margin: 10px auto;
      color: #333;
      background-color: #b6b6d0;
      padding: 20px;
      cursor: pointer;
      border: none;
      border-radius: 5px;
      &:hover {
        background-color: darken(#b6b6d0, 10%);
      }
    }
  }

  .additional-text {
    text-align: left;
    white-space: pre-line;
    font-size: 80%;
    font-style: italic;
    padding: 10px 10px 10px 90px;
    min-height: 55px;

    background-image: url("../assets/icons/idea.png");
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: 30px 5px;
  }

  .more-actions {
    a {
      cursor: pointer;
      margin: 5px;

      &:hover {
        color: darkblue
      }
    }
  }
}
</style>
