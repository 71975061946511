<template>
  <div id="guess-table">
    <div class="word" v-for="w in words" :key="w" v-bind:class="{ found: foundWords[w.id] }">
      <img :src="w.image" :alt="w.image_alt">
      <div class="word-name">{{ foundWords[w.id] ? w.name : '???' }}</div>
    </div>
  </div>
  <div class="input-form" v-show="!finished">
    <div>{{ $t('games.guess_words_game.progress', {'count': this.foundWords.filter(fw => fw).length, 'total': this.words.length}) }}</div>
    <input v-model="typedWord" v-on:keyup.enter="testWord" id="input" type="text" maxlength="15" size="10">
    <button @click="testWord()"><strong>{{ $t('global.actions.submit')}}</strong></button>
    <button @click="clearWord()">{{ $t('global.actions.clear')}}</button>
    <br><i>{{ result }}</i>
  </div>
  <div v-if="finished" class="congrats">{{ $t('games.guess_words_game.success') }}</div>
</template>

<script>
import gameContent from '../assets/games/guesswords_content.json';
export default {
  name: "GuessWordsGame",
  data() {
    return {
      finished: false,
      correctAnswersCount: 0,
      gameContent: gameContent,
      words: [],
      foundWords: [],
      typedWord: null,
      result: '',
    }
  },
  mounted() {
    this.words = gameContent[this.$route.params.lang]['administrative'];

    this.words.forEach(w => {
      this.foundWords[w.id] = false;
    });
  },
  methods: {
    testWord: function() {
      this.result = "";
      if (this.typedWord == null) {
        return;
      }

      if (!this.words.some(w => {
        // check if the typed word matches to one of the words
        if (w.words.includes(this.typedWord.toLowerCase())) {
          this.typedWord = null;
          this.foundWords[w.id] = true;
          this.result = "You found one the element: " + w.name + "!"

          // check if all words have been found
          if (this.foundWords.filter(fw => fw).length >= this.words.length) {
            this.finished = true;
            this.$emit('guessWordsGameFinished', true)
          }
          return true;
        }
      })) {
        this.result = "Not this word, try again!";
      }

    },
    clearWord: function() {
      this.typedWord = null;
    }
  }
}
</script>

<style scoped lang="scss">
#guess-table {
  .word {
    display: inline-block;
    margin: 5px;
    padding: 5px;

    .word-name {
      padding: 5px;
    }

    img {
      width: 130px;
      margin: 10px;
    }

    &.found {
      background: #46d046;
    }
  }
}
.input-form {
  margin: 30px 0;

  input {
    padding: 5px;
    margin: 10px 0;
  }

  button {
    padding: 5px;
    margin-left: 4px;
  }
}
.congrats {
  margin: 30px 0;
  font-size: 120%;
  text-decoration: underline;
}
</style>
