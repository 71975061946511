<template>
  <table class="crossword-table" @click="changeSelectedWord">
    <tr>
      <td colspan="12"></td>
      <td class="head w1">1</td>
      <td colspan="11"></td>
    </tr>
    <tr>
      <td colspan="12"></td>
      <td class="letter w1 w1-1"></td>
      <td></td>
      <td class="head w2">2</td>
      <td colspan="9"></td>
    </tr>
    <tr>
      <td colspan="10"></td>
      <td class="head w3">3</td>
      <td class="letter w3 w3-1"></td>
      <td class="letter w1 w1-2 w3 w3-2"></td>
      <td class="letter w3 w3-3"></td>
      <td class="letter w2 w2-1 w3 w3-4"></td>
      <td class="letter w3 w3-5"></td>
      <td class="letter w3 w3-6"></td>
      <td class="letter w3 w3-7">S</td>
      <td class="letter w3 w3-8"></td>
      <td class="letter w3 w3-9"></td>
      <td class="letter w3 w3-10"></td>
      <td class="letter w3 w3-11"></td>
      <td class="letter w3 w3-12"></td>
      <td></td>
    </tr>
    <tr>
      <td colspan="12"></td>
      <td class="letter w1 w1-3"></td>
      <td></td>
      <td class="letter w2 w2-2">U</td>
      <td colspan="9"></td>
    </tr>
    <tr>
      <td colspan="9"></td>
      <td class="head w4">4</td>
      <td class="letter w4 w4-1"></td>
      <td class="letter w4 w4-2">D</td>
      <td class="letter w1 w1-4 w4 w4-3"></td>
      <td class="letter w4 w4-4"></td>
      <td class="letter w2 w2-3 w4 w4-5"></td>
      <td class="letter w4 w4-6"></td>
      <td class="letter w4 w4-7"></td>
      <td class="letter w4 w4-8"></td>
      <td class="letter w4 w4-9"></td>
      <td class="letter w4 w4-10"></td>
      <td class="letter w4 w4-11"></td>
      <td class="letter w4 w4-12"></td>
      <td colspan="2"></td>
    </tr>
    <tr>
      <td colspan="12"></td>
      <td class="letter w1 w1-5"></td>
      <td></td>
      <td class="letter w2 w2-4"></td>
      <td colspan="9"></td>
    </tr>
    <tr>
      <td></td>
      <td class="head w5">5</td>
      <td class="letter w5 w5-1"></td>
      <td class="letter w5 w5-2"></td>
      <td class="letter w5 w5-3"></td>
      <td class="letter w5 w5-4">M</td>
      <td class="letter w5 w5-5"></td>
      <td class="letter w5 w5-6"></td>
      <td class="letter w5 w5-7"></td>
      <td class="letter w5 w5-8"></td>
      <td class="letter w5 w5-9"></td>
      <td class="letter w5 w5-10"></td>
      <td class="letter w1 w1-6 w5 w5-11">I</td>
      <td class="letter w5 w5-12"></td>
      <td class="letter w2 w2-5 w5 w5-13"></td>
      <td colspan="9"></td>
    </tr>
    <tr>
      <td colspan="12"></td>
      <td class="letter w1 w1-7"></td>
      <td></td>
      <td class="letter w2 w2-6"></td>
      <td colspan="9"></td>
    </tr>
    <tr>
      <td colspan="12"></td>
      <td class="letter w1 w1-8"></td>
      <td></td>
      <td class="letter w2 w2-7"></td>
      <td colspan="9"></td>
    </tr>
    <tr>
      <td colspan="12"></td>
      <td class="letter w1 w1-9"></td>
      <td></td>
      <td class="letter w2 w2-8"></td>
      <td colspan="9"></td>
    </tr>
    <tr>
      <td colspan="12"></td>
      <td class="letter w1 w1-10"></td>
      <td v-if="$route.params.lang === 'fr'"></td>
      <td v-if="$route.params.lang === 'fr'" class="letter w2 w2-9"></td>
      <td :colspan="$route.params.lang === 'fr' ? 9 : 11"></td>
    </tr>
    <tr>
      <td colspan="24"></td>
    </tr>
  </table>

  <div v-show="finished" class="congrats">{{ $t('games.crossword_game.success') }}</div>

  <span v-show="currentWord !== null" class="current-number">{{ currentWord }}: </span>
  <span v-if="words != null && currentWord != null">
    {{ words[currentWord].desc }}
  </span>
  <span v-show="currentWord == null && !finished">
    {{ $t('games.crossword_game.start') }}
  </span>

  <div v-show="currentWord !== null">
    <input v-model="typedWord" v-on:keyup.enter="testWord" id="input" type="text" maxlength="13" size="10">
    <button @click="testWord()"><strong>{{ $t('global.actions.submit') }}</strong></button>
    <button @click="clearWord()">{{ $t('global.actions.clear') }}</button>
    <br><i>{{ result }}</i>
  </div>

</template>

<script>
import gameContent from '../assets/games/crossword_content.json';
export default {
  name: "CrosswordGame",
  data() {
    return {
      gameContent: gameContent,
      words: null,
      currentWord: null,
      typedWord: null,
      foundWords: new Array(5).fill(false),
      result: null,
      finished: false
    }
  },
  mounted() {
    this.words = gameContent[this.$route.params.lang]['soft_skills'];
  },
  watch: {
    currentWord(newSelected) {
      document.querySelectorAll('td.selected').forEach(element => {
        element.classList.remove('selected');
      });
      document.querySelectorAll('td.w'+newSelected).forEach(element => {
        element.classList.add('selected');
      });
    },
    typedWord() {
      this.result = "";
    }
  },
  methods: {
    testWord: function() {
      if (this.typedWord == null) {
        return;
      }

      let toGuess = this.words[this.currentWord].word;

      if (this.typedWord.toLowerCase().replaceAll('é', 'e') === toGuess.toLowerCase()) {
        for (let i = 0; i < toGuess.length; i++) {
          let element = document.querySelector('td.w'+this.currentWord+'-'+(i+1));
          element.classList.add('selected');
          element.textContent = toGuess.charAt(i);
        }

        this.typedWord = null;
        this.foundWords[this.currentWord-1] = true;

        // test if finished
        if (this.foundWords.every(element => element === true)) {
          this.currentWord = null;
          this.finished = true;
          this.$emit('crosswordGameFinished', true)
          console.log('finished');
        } else {
          console.log("not finished");
        }
      } else {
        this.result = this.$t('games.crossword_game.not_correct');
      }
    },
    changeSelectedWord: function(event) {
      if (this.finished) {
        return;
      }

      if (-1 !== event.target.className.search('w1')) {
        this.currentWord = 1;
      } else if (-1 !== event.target.className.search('w2')) {
        this.currentWord = 2;
      } else if (-1 !== event.target.className.search('w3')) {
        this.currentWord = 3;
      } else if (-1 !== event.target.className.search('w4')) {
        this.currentWord = 4;
      } else if (-1 !== event.target.className.search('w5')) {
        this.currentWord = 5;
      }

      this.result = "";

      document.querySelector('input#input').focus();
    },
    clearWord: function() {
      this.typedWord = null;
    }
  }
}
</script>

<style scoped lang="scss">
.congrats {
  margin-bottom: 10px;
  font-size: 120%;
  text-decoration: underline;
}

table.crossword-table {
  margin: auto;
  text-align: center;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 12px;
  font-weight: bold;

  td {
    width: 20px;
    height: 20px;
    background-color: white;

    &.head {
      background-color: grey;
      color: white;
    }

    &.letter {
      border: 1px solid black;
    }

    &.selected {
      background-color: #b6b6d0;
    }

    &.w1, &.w2, &.w3, &.w4, &.w5 {
      cursor: pointer;
    }
  }
}

.current-number {
  padding: 5px;
  background: #b6b6d0;
}

input {
  padding: 5px;
  margin: 10px 0;
  text-transform: uppercase;
}

button {
  padding: 5px;
  margin-left: 4px;
}
</style>
