import { createRouter, createWebHashHistory } from 'vue-router'
import CharacterSelect from "@/components/CharacterSelect";
import MessagesPath from "@/components/MessagesPath";
import NotFound from "@/components/NotFound";

const routes = [
  {
    path: '/:lang([a-z][a-z])/',
    name: 'CharacterSelect',
    component: CharacterSelect
  },
  {
    path: '/:lang([a-z][a-z])/story/:character',
    component: MessagesPath
  },
  {
    path: '/:lang([a-z][a-z])/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    redirect: '/en'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
